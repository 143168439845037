import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../../../components/Banner/Banner';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import LoginForm from '../../../../components/Login/Login';
import * as styles from '../articles.module.scss';
import ArticleImg from '../../../../assets/IMG/freebies/team-motivation/1.png';
import articlePDF from "../../../../assets/pdfs/team motivation.pdf";

import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton
} from "react-share";
import { API_BaseURL } from '../../../../Services/ApiRoutes';
import { Link } from 'gatsby';

interface Props {
  location: string;
}

const TeamMotivationArticle: React.FC<Props> = ({ location }) => {
  const firstRef = useRef(null);
  const secondRef = useRef(null);
  const thirdRef = useRef(null);
  const fourthRef = useRef(null);
  const fifthRef = useRef(null);
  const sixthRef = useRef(null);
  const seventhRef = useRef(null);
  const shareURL: string = location ? `${(API_BaseURL === "/api/" && !location.toLowerCase().includes("beta") ? "https://primehr.co.uk/" : "https://beta.primehr.co.uk/")}Articles/Team-Motivation` : "";
  const scroll = (ref: any) => ref.current.scrollIntoView({ behavior: "smooth" });

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>PrimeHR :: Articles :: A STEP-BY-STEP GUIDE TO KEEPING YOUR TEAM HAPPY AND MOTIVATED IN 2022</title><meta charSet='utf-8' />
        <meta property="og:description" content="When you have a team, no matter its size, one of the trickiest
            challenges to overcome is keeping everyone happy and
            motivated. The smallest change could throw your whole operation off balance, and the effects of this could be
            devastating for your business." />
        <meta property="og:image" content={ArticleImg} />
        <meta property="og:title" content="PrimeHR :: A STEP-BY-STEP GUIDE TO KEEPING YOUR TEAM HAPPY AND MOTIVATED IN 2022" />
        <meta property="og:site_name" content="PrimeHR" />
        <meta property="og:url" content="https://primehr.co.uk/Articles/Team-Motivation" />
      </Helmet>

      <Banner />

      <Breadcrumb Trail={[
        { To: "/Articles", Text: "Articles" },
        { To: "", Text: "Team Motivation" }
      ]} />

      <div className={styles.Section}>
        <div className={styles.SectionInner}>
          <h1>A STEP-BY-STEP GUIDE TO KEEPING YOUR TEAM HAPPY AND MOTIVATED IN 2022</h1>
          <div className={styles.Social}>
            <FacebookShareButton url={shareURL}>
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
            <TwitterShareButton url={shareURL}>
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
            <LinkedinShareButton url={shareURL}>
              <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>

            {/* <span>Written by <Link to="https://www.linkedin.com/in/nikki-hufton-bsc-hons-mcipd-fcmi-mnlp-a7528854/">Nikki Hufton</Link></span> */}
          </div>

          <div className={styles.SectionImage}>
            <img loading={"lazy"} src={ArticleImg} />
          </div>

          <h2>KEEPING YOUR TEAM HAPPY AND MOTIVATED</h2>

          <ul>
            <li><a onClick={() => scroll(firstRef)}>1. SHARE YOUR VISION AND GOALS</a></li>
            <li><a onClick={() => scroll(secondRef)}>2. COMMUNICATE</a></li>
            <li><a onClick={() => scroll(thirdRef)}>3. PEOPLE ARE KEY</a></li>
            <li><a onClick={() => scroll(fourthRef)}>4. CREATE A HEALTHY OFFICE ENVIRONMENT</a></li>
            <li><a onClick={() => scroll(fifthRef)}>5. OFFER REGULAR FEEDBACK AND REWARD</a></li>
            <li><a onClick={() => scroll(sixthRef)}>6. OFFER THE OPPORTUNITY FOR DEVELOPMENT</a></li>
            <li><a onClick={() => scroll(seventhRef)}>7. OFFER MORE THAN A GOOD SALARY</a></li>
          </ul>

          <p>
            When you have a team, no matter its size, one of the trickiest
            challenges to overcome is keeping everyone happy and
            motivated. The smallest change could throw your whole operation off balance, and the effects of this could be
            devastating for your business.
          </p>
          <p>
            You see, once you have an unhappy, unmotivated team working for you, it
            can be very difficult - and expensive - to turn things around. Not only that
            but even one employee, who isn’t enjoying their role or working for your
            company, could impact everyone else.
          </p>
          <p>
            For you, this means loss of productivity, spending lots of time on conflict
            resolution, and probably, also, a far higher turnover of staff than is
            necessary. And we all know the headache that can come with recruitment.
          </p>
          <p>
            So now, it’s time for you to start looking at the happiness levels within your
            business and look to see how you can increase it and develop a strong, motivated, productive team of individuals who are committed to your
            business and their growth within it
          </p>
          <p>
            Depending on your company, this can either sound like a mammoth task, or
            it might simply mean a few little tweaks. Whatever your position, there’s
            usually room for improvement. Putting in some work now could mean your
            business sees a real surge in motivation and inspiration over the weeks and
            months to come.
          </p>
          <p>
            Sound good? Where do we start?
          </p>
          <p>
            Here we’ve detailed the 7 key areas that you need to look at when you’re
            looking to make real positive changes in your team. Let’s begin…
          </p>

          <h3 ref={firstRef}>1. SHARE YOUR VISION AND GOALS</h3>
          <p>
            The only way your whole business can truly cohesively work towards
            the same goal is if you have one… and everyone knows what it is!
            The right way to do this will differ from business to business, but we would advise taking the time to hold regular meetings with the
            whole business to talk about where you want the business to be, by when,
            and how you’re going to get there. At each meeting, you can update
            everyone on your progress towards your goals, both big and small, and help
            everyone to see how what they’re doing is helping you to get there.
          </p>
          <p>
            These meetings don’t have to be dull - nobody really enjoys a meeting.
            Make them fun! If it’s once every 6 months, maybe you could order in pizza.
            If you think you need more regular update meetings, bring out the good
            coffee.
          </p>
          <p>
            Keeping everyone involved with the progress will mean you also need to
            regularly set clear, measurable goals for your people, too. It’s really
            important that your employees know why these goals are being set. How
            will this help your business get to where it’s going, faster? Do this with 121
            meetings, which should be religiously carried out every month or two.
          </p>
          <p>
            You can find a template in Bronze for capturing your conversations to help
            keep a staff member on track with key pieces of work and tasks. It will also
            help identify where help or extra training may be needed and is a great way
            to check in with someone.
          </p>
          <p>
            It might sound like a lot of additional meetings, but this is your number one
            way of making sure your employees feel included and valued. It will also go
            a long way to keeping motivation high as everyone has a clear purpose.
          </p>

          <h3 ref={secondRef}>2. COMMUNICATE</h3>
          <p>
            Sounds obvious, right? But you’d be surprised how easily it is for
            communication to break down in a business. And the impact of this
            is huge. Effective communication is absolutely vital for any business that wants success. And that means two-way
            communication, not simply you communicating with your people. If they
            don’t feel they can approach you or their manager, that’s not effective
            communication.
          </p>
          <p>
            It is your job to ensure that there’s a constant flow of communication all
            across your company. Keep everyone up to date on your progress (see
            point #1) and what needs to be done, but also welcome their suggestions,
            feedback, and opinions on, not only their roles, but how they see the bigger
            picture. Everyone looks at things differently, and often, when someone isn’t
            as close to a project, or any other aspect of the business, they will be able to
            look at things more objectively. Not only will this boost morale, it could be a
            real benefit for the business as a whole. And again, your team will feel
            involved and motivated to succeed.
          </p>
          <p>
            As the business owner, you should make yourself available to everyone in
            the company. Of course, there are certain things your managers should be
            dealing with, but - especially in a small business - making it easy for your
            people to contact you can go a long way to making them feel a valuable and
            valued part of the company.
          </p>

          <h3 ref={thirdRef}>3. PEOPLE ARE KEY</h3>

          <p>
            Cohesion makes for a great business. Encourage and promote
            teamwork to boost productivity in your company. A great side effect
            of this will be creating stronger relationships between employees, and creating a really strong, engaged team.
          </p>
          <p>
            Right now, where you may have more people working remotely than you
            had before, promoting teamwork can be even more beneficial. As you may
            have seen, some people thrive working from home, but others may
            struggle. And while an element of hybrid work can mean that people gain a
            better work/life balance, it can also leave them feeling isolated or
            disengaged.
          </p>
          <p>
            It’s important to make sure that the flow of communication doesn’t stop for
            someone working away from the office.
          </p>
          <p>
            If you do have a hybrid team now, it’s even more important to arrange times
            where everyone gets together, whether that’s by planning team building
            exercises, or by scheduling company meetings in one location (where
            possible).
          </p>
          <p>
            You can use our downloadable ME manual and get everyone to fill in their
            own, then get them to share it with one another to see how they can work
            best together.
          </p>
          <p>
            By keeping your teams close, it creates a special culture in the business
            where there’s a genuine bond between employees. This also means that
            when it comes time to hire new people, you have to consider how well they
            will integrate into the team. Look for the right character traits and attitudes
            over skills that can be taught, so that your carefully developed business
            culture isn’t impacted by a misjudged hire.
          </p>

          <h3 ref={fourthRef}>4. CREATE A HEALTHY OFFICE ENVIRONMENT</h3>

          <p>
            If there’s anything the last couple of years have taught us, it’s just how
            important our physical working environment is. When working from
            home, we quickly learned that getting dressed and sitting in a work-dedicated area made us more productive and switched our
            mindset from home to work. Lounging on the sofa in pyjamas simply didn’t
            cut it.
          </p>
          <p>
            It’s just as important to create the right environment in your office.
          </p>
          <p>
            Consider things like noise, temperature, privacy, lighting, and ambience. Get
            the balance right and people will work at their optimum. Get even one
            element wrong and it can be really distracting. Glare from the window. Aircon blasting. A too-loud radio. These can all stop productivity in their tracks
            and make for miserable employees. Make sure everyone is comfortable,
            that desks are big enough, chairs comfortable, and all those environmental
            factors are considered too.
          </p>
          <p>
            You can take this a step further and look at things like lunches and snacking
            in the office. Perhaps suggest or offer healthier alternatives, replacing crisps and chocolate with fruit and nuts, for example. Save the treats for birthdays
            and other special occasions.
          </p>
          <p>
            You may also want to encourage better physical health amongst your team,
            too. Perhaps taking a walk to have a chat or informal meeting rather than
            sitting; or even a company challenge to get people moving. If you can
            arrange it, perhaps offering a discounted gym membership would be a
            good perk for your people, to encourage good health. You could even
            consider arranging a regular class for your team, like a weekly yoga session,
            or martial art lessons.
          </p>
          <p>
            All of this will make for more relaxed, happier employees, but it’s important
            to remember that physical activity with colleagues isn’t for everyone. Don’t
            force it upon anyone or pressure anyone into taking part in anything they
            feel uncomfortable with.
          </p>
          <p>
            Don’t forget your team’s mental health. Keep an eye out for signs of stress,
            anxiety or overwhelm, and make sure that all of your managers know the
            signs to look out for, too. If you do spot any of the warning signs, take
            immediate action by having a confidential chat with your employee and
            offering the right support to help them cope.
          </p>
          <p>
            And, as with all of the other points in this guide, don’t forget your remote
            workers. Make sure their working environment is comfortable, and that
            they have everything they need to be able to do their job. Include them in
            any fitness initiatives, and schedule regular catch-ups so that you can spot
            any signs of declining mental health.
          </p>
          <p>
            By making all of your health initiatives available and accessible, regardless
            of where your team are based, will mean you'll need to change the way you
            think, which will ensure everyone is included all of the time.
          </p>

          <h3 ref={fifthRef}>5. OFFER REGULAR FEEDBACK AND REWARD</h3>

          <p>
            Are your people doing an amazing job? Tell them! While you might
            be beaming on the inside because of something an employee has done, it might not always be obvious that you’re
            thrilled. Praise is often overlooked in the workplace, but recognition
            - private or public - can go a really long way towards inspiring your team
            and encouraging real progress.
          </p>
          <p>
            And, if someone has done a good job, don’t just say “good job”. Tell them
            WHY it was a good job and the impact it has had (has it moved you closer
            towards hitting a goal? Has it won new clients? Has it added new skills to
            the business?).
          </p>
          <p>
            Not only does this approach motivate your people, but it also helps them to
            understand what you want and need from them going forward.
          </p>
          <p>
            It’s equally important to let your people know if they’ve done something
            detrimental. It doesn’t have to mean a telling off or a warning, but helping
            people understand their mistakes means they’re far less likely to repeat
            them in future.
          </p>
          <p>
            If someone has done an exceptionally good job with something, you may
            want to consider a reward. This doesn’t have to be a grand gesture, but
            simply something to say well done. This could range from an ice cream for
            the team on a hot day, to a bottle of something or a gift card for an
            individual achievement, or even more responsibility for someone working
            towards a professional goal.
          </p>

          <h3 ref={fifthRef}>6. OFFER THE OPPORTUNITY FOR DEVELOPMENT</h3>

          <p>
            The majority of your team will feel more valuable - and valued - when
            they’re learning and enhancing their skills. Provide everyone who wants it the opportunity for development and growth within
            the business.
          </p>

          <p>
            This isn’t a one-size-fits-all thing. You will need to tailor learning and
            development opportunities for each and every member of your team. But
            it’s not as complicated as it sounds.
          </p>
          <p>
            You should know from your regular 121 meetings that each person on your
            team has individual personal and professional goals. They will have
            ambition to move into a certain role, or to gain particular skills. Look at
            what they’ll need to develop and achieve each goal, and identify the training
            or responsibility they’ll require to accomplish this growth.
          </p>
          <p>
            Your solution may come from training courses, but it could also come from
            shadowing another employee, or from coaching or mentoring from
            someone within the business. Work with your employees to decide on the
            most appropriate course of action for each particular goal, and set a
            timeframe for each target to be hit.
          </p>
          <p>
            While you can facilitate growth and guide your people on the right path,
            make sure this is ultimately being driven by your employees. They need to
            create their own goals for development and identify the areas that they
            would like to work on for their learning and progress.
          </p>

          <p>
            That said as a small business owner you also need to make sure you can
            align an individuals learning and career development with your business
            strategy. i.e Are they learning to close gaps in their knowledge to do a
            better job in their current role or is it totally unrelated? If you have a small
            tea you may also want to skills share so that you always have cover when
            someone is on holiday or absent so you can provide continuity of service to
            your own clients or customers.
          </p>

          <h3 ref={seventhRef}>7. OFFER MORE THAN A GOOD SALARY</h3>

          <p>
            In today’s job market, candidates have the upper hand. They can afford
            to be a little choosier with the jobs they accept, because there are more I jobs than candidates. It also means that it’s a great time for employees
            to jump ship, if they’re not really happy where they are.
          </p>
          <p>
            For you, that means you really need to sell your business to any
            prospective employees, and to offer the right things to attract and retain
            the very best people. And that means more than a good salary.
          </p>
          <p>
            Of course, people want to be paid well for the job they do, but they also
            want other benefits.
          </p>
          <p>
            Offering things like flexible working, a fantastic culture, and perks like
            subsidised gym memberships, wellness programmes, opportunities for
            growth or even help with childcare can really make your business stand out
            - and make your employees happier.
          </p>
          <p>
            Over the past couple of years, we’ve realised that our work/life balance is
            really important. And lots of companies have done more to support their
            employees to regain theirs.
          </p>
          <p>
            Flexibility, when it comes to working hours and location, has been one of
            the biggest things to come out of the pandemic. We’ve realised that we
            don’t need to have a workforce in the same place at the same time in order
            to be productive. In fact, doing the opposite of that has helped many of us
            to get more done and to be happier while we’re doing it.
          </p>
          <p>
            If you can give your people a better work/life balance, shout about it! Make
            sure the people are aware of their options when it comes to working hours
            and where they work from, and you should be rewarded with a team that is
            happy, motivated, and productive.
          </p>
          <p>
            Of course, you need to work on all of these things to create a truly happy
            and engaged team. No one thing will work on its own. And that may seem
            like a big task, but it really doesn’t have to be.
          </p>
          <p>
            If you think you could use a helping hand on implementing the above, we’d
            love to support you. Give us a call today to see how we can help you build a
            stronger, happier, more motivated team this year.
          </p>
        </div>
        <div className={styles.SectionSide}>
          <div className={styles.Download}>
            <a download href={articlePDF}>
              <i>
                <FontAwesomeIcon icon={faCloudDownloadAlt} />
              </i>
              Download Article
            </a>
          </div>

          <div className={styles.SectionSideInfo}>
            <h4>Found This Article Useful?</h4>
            <p>If you found this article or any of our FREE resources useful then sign up now to start using our amazing new features and tools, and take advantage of our <Link to={"/Packages/Silver"}>Silver</Link> and <Link to={"/Packages/Silver"}>Gold</Link> Packages!</p>

            <div className={styles.Download}>
              <Link to="/Register">
                Create an Account
              </Link>
            </div>
          </div>

          <h4><span>Or</span></h4>

          <LoginForm />
        </div>
      </div>
    </>
  );
};

export default TeamMotivationArticle;