import React from 'react';
import Layout from '../../components/Layout/layout';
import TeamMotivationArticle from '../../Views/Website/Articles/Team-Motivation/team-motivation';

const EducationalGuideArticlePage: React.FC = (props: any) => {
  return (
    <Layout>
      <TeamMotivationArticle location={props.location.href} />
    </Layout>
  );
};

export default EducationalGuideArticlePage;
